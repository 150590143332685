<template>
  <div class="d-flex flex-column ml20">
    <div class="d-flex align-items-center">
      <p class="fs8 cA3">跳转至</p>
      <el-select
          v-model="btnText"
          style="width: 150px;"
          placeholder="请选择"
          @change="select_tap($event)"
        >
          <el-option
            v-for="(v) in optionList"
            :key="v.text"
            :label="v.text"
            :value="v.text"
          />
        </el-select>
      </div>
      <el-button size="small"
                 style="width: 120px;margin-top:10px;"
                 type="primary"
                 v-if="typeFiltration"
                 @click="dialogShow_tap">
            {{'选择' + btnText}}
      </el-button>
      <!-- 商品选择 -->
      <goodsList :memberData="goodsList"
                 :dialogWidth="'60%'"
                 :title="btnText"
                 :loading="loading"
                 :dialogTableVisible="dialogTableVisible"
                 :total="total"
                 :selectType="selectType"
                 :selectGoods="selectGoods"
                 :currentSelect="currentSelect"
                 @dialogClose="dialogClose"
                 @onConfirmShop="onConfirmShop"
                 @dialogCurrentChange="dialogCurrentChange">
      </goodsList>
      
  </div>
</template>

<script>
import {
  getGoodsInfoPCShop,
} from "@/api/miniProgram";
import {
  fetchAppointmentGoodsList,
} from "@/api/companyManage.js";
import {
  selectGoodsTypeInfoPC,
  // @ts-ignore
} from "@/api/coupon.js";
import goodsList from "./goodsList.vue";
import { getDataTimeSec } from "@/utils";
export default {
  props:{
    index: {
      type: Number,
      default:0,
    },
    type: {
      type: String,
    },
    title: {
      type: String,
    },
    selectGoods:{
      type: Array
    },
    currentGoods:{
      type: Object
    },
    currentSelect:{
      type: Number,
      default:null,
    }
  },
  data() {
    return {
      optionList:[
        {
          text: "无",
        },
        {
          text: "名片页",
          isPages:true,
          pagePath: "pages/index/main",
        },
        {
          text: "媒体页",
          isPages:true,
          pagePath: "pages/media/index",
        },
        {
          text: "商城页",
          isPages:true,
          pagePath: "pages/Products/main",
        },
        {
          text: "动态页",
          isPages:true,
          pagePath: "pages/Dynamic/main",
        },
        {
          text: "官网页",
          isPages:true,
          pagePath: "pages/WebSite/main",
        },
      ],
      btnText:'无',
      dialogTableVisible:false,
      pageSize: 10, //显示条数
      currentPage:1,
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      selectType:'',
      typeFiltration:'',
      goods:'',
      goodsType:''
    };
  },
  components:{
    goodsList,
  },
  watch:{
    btnText(val){
      if(this.filtration.includes(val)){
        this.$set(this,'typeFiltration',false)
      }else{
        this.$set(this,'typeFiltration',true)
      }
    },
    title(val){
      this.btnText = val
    }
  },
  computed:{
    filtration(){
      let arr = [
        '无',
        '名片页',
        '媒体页',
        '商城页',
        '动态页',
        '官网页',
      ]
      return arr
    }
  },

  methods:{
    select_tap(val){
      let than = this
      let data = ''
      this.currentPage = 1
      this.goodsType = '' //置空选择项
      this.goods = [] //置空选择项
      this.goodsList = ['']
      this.goodsList = []
      if(this.currentSelect){

        this.$emit('currentSelect_tap',this.currentSelect)
      }
      this.optionList.forEach((v,k) => {
        if(v.text == val){
          data = v
        }
      });
      if(data.type){
        this.btnText = data.text
      }
      if(data.type){
        this.$emit('select_tap',this.index,val,data.type)
        this.selectType = data.type
      }else{
        if(than.type == 'appointment'){
          this.$emit('select_tap',this.index,val,than.type,'',data)
        }else{
          this.$emit('select_tap',this.index,val,data,'',this.currentSelect)
        }

      }


    },
    addImg(){
      this.optionList.push({
        title:'无',
        img:'',
      })
    },
    queryShop(){
      let data = {
        pageNum: this.currentPage
      }
      this.loading = true
      data[this.selectType] = 1
      getGoodsInfoPCShop(data)
      .then(res=>{
        if(res.data){
          this.loading = false;
          let data = res.data
          if(data.pageInfo){
             this.total = data.pageInfo.total
             this.goodsList = data.pageInfo.list
          }else if(data.data.pageInfo){
             this.total = data.data.pageInfo.total
             this.goodsList = data.data.pageInfo.list
          }
        }else{
          this.goodsList = []
        }

      })
      .catch(err=>{
        this.loading = false;
      })
    },
    dialogShow_tap(){
      if(this.selectType && this.selectType !== 'classify' && this.selectType !== 'appointment'){//商品
        this.queryShop()
      }else if(this.selectType == 'classify'){//商品分类
        this.queryClassify()
      }else if(this.selectType == 'appointment'){//预约商品
        this.queryAppointment()
      }
      this.dialogTableVisible = true;

    },
    queryClassify(){
      let data = {
        pageNum: this.currentPage
      }
      this.loading = true
      selectGoodsTypeInfoPC(data)
      .then(res=>{
        if(res.data){
          this.loading = false;
          let data = res.data.pageInfo
          this.total = data.total
          this.goodsList = data.list
        }else{
          this.goodsList = []
        }
      })
      .catch(err=>{

        this.loading = false;
      })
    },
    queryAppointment(){
      let than = this
      let data = {
        pageNum: this.currentPage
      }
      this.loading = true
      fetchAppointmentGoodsList(data)
      .then(res=>{
        if(res.data){
          than.loading = false;
          let data = res.data.pageInfo
          than.total = data.total
          than.goodsList = data.list
          than.goodsList.forEach(v=>{
            v.price = JSON.parse(v.price);
            if(typeof(v.price)=="object"){

              v.price = v.price[0] + '~' + v.price[1]
            }
          })
        }else{
          than.goodsList = []
        }
      })
      .catch(err=>{
      })
    },
    //弹出框关闭
    dialogClose(){
      this.dialogTableVisible = false
    },
    dialogCurrentChange(val){
      this.currentPage = val
      this.queryShop()
    },
    onConfirmShop(goods,type,currentSelect){
      this.goods = goods
      this.goodsType = type
      this.$emit('select_tap',this.index,this.btnText,this.selectType,goods,currentSelect)
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
